
import { Component, Prop, Vue } from "vue-property-decorator";

/**
 * Display a 404 page
 */
@Component({
  name: "SharedError404",
  components: {},
})
export default class Error404 extends Vue {
  /**
   * Seconds remaining until redirected to the home page
   */
  @Prop({ default: 0 })
  private countdown!: number;

  private get time(): string {
    let time = this.countdown.toString();
    if (time.length === 1) {
      return `00:00:0${time}`;
    }
    return `00:00:${time}`;
  }
}
